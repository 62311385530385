import type Role from "../../../common/src/models/postgres/role";
import type User from "../../../common/src/models/postgres/user";
import type { requestContext } from "@fastify/request-context";
import Permission from "../models/postgres/permission";
import RolePermission from "../models/postgres/rolePermission";
import UserConfig from "../models/postgres/userConfig";

export enum ErrorMessage {
  Invalid_Input = "Invalid input",
  OTP_Invalid = "Invalid OTP Code",
  User_Existed = "User already existed",
  User_Not_Existed = "User not existed",
  Un_Authorized = "Authorization header seems missing",
  Access_Denied = "You do not have permission to access this resource",
  Account_Blocked = "This account has been locked",
  Invalid_Input_Password = "Invalid input password",
  Email_Invalid = "Email invalid",
  Invalid_Input_Temporary_Password = "Invalid input temporary password",
  Invalid_Cap_Auth_Key = "Invalid CAP_AUTH_KEY",
  Invalid_Encrypt_Key = "Invalid ENCRYPT_KEY",
  Invalid_Zip_File = "Invalid structure of zip file",
  Empty_Parameters = "Empty parameters",
}

export enum CommonStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum RequestMethod {
  GET = "get",
  POST = "post",
  PUT = "put",
  DELETE = "delete",
  OPTIONS = "options",
  HEAD = "head",
}

export enum ErrorCode {
  Unknown_Error = 0,
  Invalid_Input = 1,
}

export enum GroupRole {
  Enterprise = 1,
  Reseller = 2,
}

export enum SimStatus {
  ActovatonReady = "ACTIVATION_READY_NAME",
  Replaced = "REPLACED_NAME",
  Activated = "ACTIVATED_NAME",
  Deactivated = "DEACTIVATED_NAME",
}

export enum SimTypeChange {
  Status = 3,
}

export enum STATUS_SIM {
  PRE_ACTIVATED = "PRE_ACTIVATED",
  SUSPENDED = "SUSPENDED",
  AVAILABLE = "AVAILABLE",
  TERMINATED = "TERMINATED",
  TARIFF_HOLIDAY = "TARIFF_HOLIDAY",
  ACTIVE = "ACTIVE",
  ACTIVATED_NAME = "ACTIVATED_NAME",
  DEACTIVATED_NAME = "DEACTIVATED_NAME",
  INVENTORY_NAME = "INVENTORY_NAME",
  ACTIVATION_READY_NAME = "ACTIVATION_READY_NAME",
  RETIRED_NAME = "RETIRED_NAME",
  NTT_COM_ACTIVE = "NTT_COM_ACTIVE",
  NTT_COM_DEACTIVE = "NTT_COM_DEACTIVE",
}

export enum SortType {
  ASC = "ASC",
  DESC = "DESC",
}

interface RolePermissionAndPermission extends RolePermission {
  permission: Permission;
}

interface RoleAndRolePermissions extends Role {
  permissions: RolePermissionAndPermission[]
}

export interface JoinUserAndRole extends User {
  role?: RoleAndRolePermissions | null
}

export interface JoinUserAndRoleAndConfig extends JoinUserAndRole {
  userConfig: UserConfig | null;
}

export enum Lang {
  JA = "ja",
  EN = "en",
};

declare module "@fastify/request-context" {
  interface RequestContextData {
    [RequestContextKey.USER]: JoinUserAndRoleAndConfig | null;
  }
}

export enum RequestContextKey {
  USER = "user",
}

export enum PermissionKey {
  // GROUP
  GROUP__CREATE = "GROUP__CREATE",
  GROUP__UPDATE = "GROUP__UPDATE",
  GROUP__DELETE = "GROUP__DELETE",
  GROUP__DELETE_MULTIPLE = "GROUP__DELETE_MULTIPLE",
  // SIM
  SIM__CREATE = "SIM__CREATE",
  SIM__UPDATE = "SIM__UPDATE",
  SIM__DELETE = "SIM__DELETE",
  // USER
  USER__CREATE = "USER__CREATE",
  USER__UPDATE = "USER__UPDATE",
  USER__DELETE = "USER__DELETE",
  // SETTING
  SETTING__LANGUAGE_DATETIME = "SETTING__LANGUAGE_DATETIME",
  SETTING__THEME = "SETTING__THEME",
  SETTING__SYSTEM_MAIL = "SETTING__SYSTEM_MAIL",
  SETTING__NOTIFY_PASSWORD = "SETTING__NOTIFY_PASSWORD",
  SETTING__PASSWORD_SECURITY = "SETTING__PASSWORD_SECURITY",
  // LOGS
  LOGS__SYSTEM_LOG = "LOGS__SYSTEM_LOG",
  LOGS__RAW_LOG = "LOGS__RAW_LOG",
  // STATISTIC
  STATISTIC__MANAGEMENT = "STATISTIC__MANAGEMENT",
  // OPENAPI
  OPENAPI_GENERATE = "OPENAPI_GENERATE",
  // ACCESS RESTRICTIONS
  SETTING__ACCESS_RESTRICTIONS = "SETTING__ACCESS_RESTRICTIONS",
  // OTA
  OTA__CREATE = "OTA__CREATE",
  OTA__UPDATE = "OTA__UPDATE",
  OTA__DELETE = "OTA__DELETE",
}

export enum WebSocketEvent {
  Ping = "PING",
  SimUpdated = "SIM_UPDATED",
  SimDataRecieved = "SIM_DATA_RECIEVED",
  OTALog = "OTA_LOG",
}

export enum RoleName {
  ROOT = "ROOT",
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
}

export enum RoleId {
  ROOT = 1,
  ADMIN = 2,
  MEMBER = 3,
}

export enum WeekDay {
  SUNDAY = 1,
  MONDAY = 2,
  TUESDAY = 3,
  WEDNESDAY = 4,
  THURSDAY = 5,
  FRIDAY = 6,
  SATURDAY = 7,
}

export enum AmPm {
  AM = 1,
  PM = 2,
}

export enum SettingType {
  SYSTEM_MAIL = "system_mail",
  NOTIFY_PASSWORD_INVITATION = "notify_password_invitation",
  NOTIFY_PASSWORD_AUTHENTICATION = "notify_password_authentication",
  SYSTEM_CONFIG_PASSWORD_MINIMUM_LENGTH_KEY = "system_password_minimum",
  SYSTEM_CONFIG_PASSWORD_CHARACTER_UPPER_CASE_KEY = "system_password_upper_case",
  SYSTEM_CONFIG_PASSWORD_CHARACTER_LOWER_CASE_KEY = "system_password_lower_case",
  SYSTEM_CONFIG_PASSWORD_CHARACTER_NUMBER_KEY = "system_password_number",
  SYSTEM_CONFIG_PASSWORD_CHARACTER_SYMBOL_KEY = "system_password_symbol",
  SYSTEM_CONFIG_PASSWORD_MINIMUM_LENGTH = 8,
  SYSTEM_CONFIG_GOOGLE_MAPS_API_KEY_KEY = "system_config_google_maps_api_key",
  USER_LANGUAGE = "user_language",
  SYSTEM_CONFIG_SIM_ONLINE_TERM = "system_config_sim_online_term",
}

export enum CommonValueCheckbox {
  ACTIVE = 2,
  INACTIVE = 1,
}

export enum UserConfigType {
  SYSTEM = 1,
  USER = 2,
}

export const VERSION_DEFAULT = "1.0.0";

export enum MsgAwsCognito {
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
  SOFTWARE_TOKEN_MFA = "SOFTWARE_TOKEN_MFA",
}

export enum MemberStatus {
  ACTIVE = 1,
  IN_CONFIRM = 2,
  INACTIVE = 0,
}

export enum ErrorLogAction {
  MEMBER_DELETE = "DELETE MEMBER",
  MEMBER_UPDATE = "UPDATE MEMBER",
  MEMBER_ADD = "ADD MEMBER",
  //XOMG

  SIM_ADD = "ADD SIM",
  SIM_UPDATE = "UPDATE SIM",
  SIM_DELETE = "DELETE SIM",
  SIM_WEBHOOK = "WEBHOOK SIM",
  SIM_SQS = "SQS SIM",
  SIM_CRONJOB = "CRONJOB SIM",
  SIM_IMPORT = "SIM_IMPORT",
  SIM_EXPORT = "SIM_EXPORT",

  RECIEVE_FROM_SIM = "RECIEVE FROM SIM",

  SETTING_CHANGE = "CHANGE SETTING",

  AUTH_CREATE_ACCOUNT = "CREATE ACCOUNT AUTH",
  AUTH_FORGOT_PASSWORD = "FORGOT PASSWORD AUTH",

  PASSWORD_SETTING_CHANGEMFA = "CHANGE MFA SETTING",
}

export enum EVENT_BRIDGE_TYPE {
  UPDATE_STATUS = 1,
}

export enum ConnectionSim {
  OTA = 2,
  ONLINE = 1,
  OFFLINE = 0,
}

export enum ImeiStatus {
  NOT_CHANGED = '0',
  CHANGED = '1',
}

export enum AppletType {
  Poller = 0,
  Other = 1,
}

export enum OTAStatus {
  Failed = "Failed",
  Started = "Started",
  Succeeded = "Succeeded",
  Interrupted = "Interrupted",
  Ready = "Ready",
  Blocked = "Blocked",
  NotFound = "Not found",
}

export enum EventCode {
  POLL = 1,
  COMMAND_DATA = 2,
  LOAD_COMMAND = 3,
  SCP_INIT = 4,
  RES_FIRST_CHUNK = 5,
  RES_COMMAND_APDU = 6,
  CLOSE_SESSION = 7,
}

/**
 * 100番台：例外エラー
 * 200番台：サーバーエラー(無効なデータなど)
 * 300番台：認証エラー
 * 400番台：通信エラー
 * 500番台：SIMからのレスポンスエラー
 */
export enum ReasonCode {
  EXCEPTION = 100,
  MISSING_OTA_SETTING = 101,
  NOT_EXIST_APUD = 200,
  INVALID_CHUNK = 201,
  EMPTY_CHUNK = 202,
  NOT_RESITERED_PARAMETER = 203,
  FAILED_AUTHORIZE = 300,
  NOT_EXIST_SESSION = 400,
  ABORTED_SESSION = 401,
  MAX_RETRY_COUNT = 402,
  R_APDU_ERROR = 500,
  POLLER_UPDATE_IS_BLOCKED = 501,
}

export enum ClientType {
  WebUI = "WEB_UI",
  OpenAPI = "OPEN_API",
}

export enum ActionStatus {
  Success = "Request return success",
  Error = "Request return error",
}

export enum PollingType {
  Default = 0, // NOTE: 有効・初回起動時のみの2つを使用できる
  Resiliency = 1, // NOTE: 有効・初回起動時のみ・無効の3つを使用できる
}

export enum StandbyPollerStatus {
  Unknown = 0,
  Exist = 1,
  NotExist = 2,
}

export enum ActionTypeId {
  INSTALL_PACKAGE = 1,
  REMOVE_PACKAGE = 2,
  STORE_DATA = 3,
  RFM = 4,
}